body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.AppContainer {
  display: flex;
  flex-direction: column;
  // min-height: 100vh;
  justify-content: center;
}

.wallet-area {
  display: flex;
  align-items: center;
  justify-content: center;
  .wallet-address {
    margin-right: 1rem;
    display: flex;
  }
}

.mint-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 16px;
    z-index: 3;
    font-family: "Sofia Pro", sans-serif;
    max-width: 665px;
    font-weight: 500;
    margin: 0 0 1em;
  }
  .action-area {
  }
  .quantity-input-area {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    span {
      white-space: nowrap;
      font-size: 20px;
      z-index: 3;
      font-family: "Sofia Pro", sans-serif;
      max-width: 585px;
      font-weight: 500;
    }
    .separator {
      margin: 0 6px;
    }
    input {
      width: auto;
      border: 1px solid #00ddee;
      box-shadow: 0px 0px 15px #29cad4;
      font-family: Burbank Big Condensed;
      font-style: normal;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.75px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      padding: 10px 15px;
      outline: none;
      background: none;
      border-radius: 0;
      text-align: center;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: calc(100% - 4px);
      background-position-y: 5px;
      
      width: auto;
      border: 1px solid #00ddee;
      box-shadow: 0px 0px 15px #29cad4;
      font-family: Burbank Big Condensed;
      font-style: normal;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.75px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      padding: 6px 30px 6px 15px;
      outline: none;
      border-radius: 0;
      text-align: center;
      cursor: pointer;
    }

    .insufficient {
      color: red;
    }
  }
}
.mint-btn {
  .action-button {
    .btn {
      border: 3px solid #00ddee;
      box-shadow: 0px 0px 15px #29cad4;
      font-family: Burbank Big Condensed;
      font-style: normal;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.75px;
      text-align: center;
      color: #ffffff;
      padding: 10px 15px;
      outline: none;
      background: none;
      border-radius: 0;
      cursor: pointer;
    }
  }
  .link {
    text-decoration: underline;
    cursor: pointer;
  }
}

nav {
  .nav-toggler {
    display: flex;
    align-items: center;
    .nav-item.dropdown {
      margin-left: 1rem;
      .dropdown-menu {
      }
      .wallet-info {
        padding: 2px 8px;
        font-size: 12px;
        text-align: center;
      }
      .link-disconnect {
        text-align: center;
      }
    }
    > a {
      margin-left: 4px;
    }
  }
  #wallet-dropdown {
    border: 1px solid #00ddee;
    font-size: 12px;
    white-space: nowrap;
  }
}

.transaction-message {
  font-size: 12px;
  .link_opensea {
    &:hover {
      color: #29cad4;
    }
  }
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 900px;
  .hero-content {
    background-size: 100% 100%;
    padding-bottom: 7vh;
    > p {
      max-width: 725px;
    }
  }
  .bgFootball {
    position: absolute;
    top: 35%;
  }
  .menu {
    li {
      margin: 0 2px;
    }
  }
}

#quantity-selector {
  .dropdown-toggle {
    background: transparent;
    width: auto;
    border: 1px solid #00ddee;
    box-shadow: 0px 0px 15px #29cad4;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    outline: none;
    border-radius: 0;
    span {
      font-family: Burbank Big Condensed;
      font-style: normal;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.75px;
      min-width: 24px;
      display: inline-block;
    }
  }
  .dropdown-menu {
    min-width: 64px;
    background: #171839;
    .dropdown-item {
      font-family: Burbank Big Condensed;
      font-style: normal;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.75px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      &:hover {
        background: lighten(#171839, 10%);
      }
    }
  }
}

.characters {
  .img-nft {
    height: auto;
    display: block;
    margin-bottom: 15px;
  }
}

.wallet-connections-modal {
  .modal-body {
    padding: 0.5rem;
  }
}
.wallet-connections {
  display: flex;
  flex-direction: column;
  .btn {
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      margin: 0.5rem 0;
      border-radius: 6px;
    }
    span {
      font-weight: bold;
    }
  }
}